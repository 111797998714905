import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { HeaderItem, headers } from ".";
import { _axios } from "../../api";
import SortImageList from "../../components/ImageListSort";
import SettingFormItems, {
	SettingItem,
	SettingItems,
} from "../../components/SettingFormItems";
import { createSlug } from "../../features/addMenu";
import { findWidgetByName } from "../../utils";
import { adminTokenCookieName } from "../../utils/constants";
import { updateWidgetItem } from "../../utils/requests";
import { showToastV2 } from "../WidgetBuilder";

type SettingsNames =
	| "bgColor"
	| "topBgColor"
	| "bottomBgColor"
	| "topTextColor"
	| "bottomTextColor"
	| "containerType"
	| "showCartIcon"
	| "searchBar"
	| "showPhone"
	| "phoneNumber"
	| "showFloatingCart";

const headerSettings: SettingItem<SettingsNames>[] = [
	{
		name: "Container Type",
		key: "containerType",
		type: "select",
		options: [
			{ name: "Container", value: "container" },
			{ name: "Container Full", value: "full" },
		],
	},
	{
		name: "Show Cart Icon",
		key: "showCartIcon",
		type: "checkbox",
	},
	{
		name: "Search Bar",
		key: "searchBar",
		type: "checkbox",
	},
	{
		name: "Show Floating Cart",
		key: "showFloatingCart",
		type: "checkbox",
	},
	// {
	// 	name: "Show Phone",
	// 	key: "showPhone",
	// 	type: "checkbox",
	// },
	// {
	// 	name: "Phone Number",
	// 	key: "phoneNumber",
	// 	placeholder: "Phone Number",
	// 	type: "text",
	// },
];

const colorSettings: SettingItem<SettingsNames>[] = [
	{
		name: "Top Background Color",
		key: "topBgColor",
		type: "color",
	},
	{
		name: "Bottom Background Color",
		key: "bottomBgColor",
		type: "color",
	},
	{
		name: "Top Text Color",
		key: "topTextColor",
		type: "color",
	},
	{
		name: "Bottom Text Color",
		key: "bottomTextColor",
		type: "color",
	},
];

const HeaderSettings = () => {
	const [settings, setSettings] = useState<SettingItems<SettingsNames>>({});
	const [selectedHeader, setSelectedHeader] = useState<HeaderItem>();

	const [menues, setMenues] = useState<any[]>([]);
	const [mainMenu, setMainMenu] = useState<any>();
	const [navMenu, setNavMenu] = useState<any>();

	const [layouts, setLayouts] = useState<number[]>([]);

	const [menuText, setMenuText] = useState<string>("");
	const [showMenu, setShowMenu] = useState<"main" | "nav">();
	const [menuPagination, setMenuPagination] = useState<{
		total: number;
		lastPage: number;
		currentPage: number;
	}>({
		total: 0,
		lastPage: 1,
		currentPage: 1,
	});

	const [themeSettingsW, setThemeSettingsW] = useState<any | null>(null);

	const menuSuggestion = useDetectClickOutside({
		onTriggered: () => setShowMenu(undefined),
	});

	useEffect(() => {
		findWidgetByName("Theme Settings").then((res) => {
			setThemeSettingsW(res);

			setSettings({
				bgColor: res?.settings?.headerSettings?.bgColor || "",
				containerType:
					res?.settings?.headerSettings?.containerType || "",
				showCartIcon:
					res?.settings?.headerSettings?.showCartIcon ?? true,
				searchBar: res?.settings?.headerSettings?.searchBar ?? true,
				showPhone: res?.settings?.headerSettings?.showPhone ?? true,
				phoneNumber: res?.settings?.headerSettings?.phoneNumber || "",
			});

			if (res?.settings?.headerSettings?.mainMenu)
				setMainMenu({
					name: res?.settings?.headerSettings?.mainMenu || "",
					slug: res?.settings?.headerSettings?.mainMenu || "",
				});

			if (res?.settings?.headerSettings?.navMenu)
				setNavMenu({
					name: res?.settings?.headerSettings?.navMenu || "",
					slug: res?.settings?.headerSettings?.navMenu || "",
				});

			const selectedHeader = headers.find(
				(header) => header.id == res?.settings?.headerId
			);
			setSelectedHeader(selectedHeader);

			if (selectedHeader?.layouts?.length) {
				setLayouts(
					res?.settings?.headerSettings?.layouts ||
						Array.from(
							{ length: selectedHeader?.layouts?.length },
							(v, i) => i + 1
						) ||
						[]
				);
			}
		});

		_axios
			.get(process.env.REACT_APP_BUILDER_URL + `menu`, {
				headers: {
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			})
			.then((res) => {
				setMenues(res.data);
			});
	}, []);

	const handlePublish = () => {
		const headerSettings = {
			...themeSettingsW.settings?.headerSettings,
			...settings,
			layouts,
			mainMenu: createSlug(mainMenu?.name),
			navMenu: createSlug(navMenu?.name),
		};

		updateWidgetItem({
			...themeSettingsW,
			status: "published",
			settings: {
				...themeSettingsW.settings,
				headerSettings,
			},
			active: true,
		})
			.then((res) => {
				showToastV2("Header updated successfully");
			})
			.catch(() => {
				showToastV2("Header update failed!", "error");
			});
	};

	const handleToDraft = () => {
		console.log("Safe to draft");
	};

	const handleMenuSearch =
		(type: "main" | "nav") => (e: React.ChangeEvent<HTMLInputElement>) => {
			const target: string = e.target.value;
			setMenuText(target);
			setMenuPagination({
				total: 0,
				lastPage: 1,
				currentPage: 1,
			});
			setShowMenu(type);
		};

	const handleMenuClick = () => {
		setMenuText("");
		setShowMenu(undefined);
	};

	console.log("layouts :>> ", layouts);

	return (
		<div className="p-4">
			<div className="sticky top-[60px] left-0 z-50 bg-white py-4 w-full shadow-md px-2 flex justify-between">
				<h1 className="text-2xl font-bold">Header Settings</h1>

				<div className="flex gap-4 items-center">
					{/* <button
						className="bg-gray-500 text-white px-4 py-2 rounded-md"
						onClick={handleToDraft}>
						Safe to draft
					</button> */}

					<button
						className="bg-green-500 text-white px-4 py-2 rounded-md"
						onClick={handlePublish}>
						Publish
					</button>
				</div>
			</div>
			{!!layouts.length && (
				<div className="mt-4 border border-gray-300 px-4 py-1">
					<p className="text-lg font-semibold">Header Layout :</p>

					<div className="flex gap-4 items-center">
						<p>Mobile :</p>
						<SortImageList
							photos={layouts.map(
								(l) => selectedHeader?.layouts?.[l - 1] || ""
							)}
							onUpdate={(photos) => {
								setLayouts(
									photos.map((photo, index) => {
										const layoutIndex =
											selectedHeader?.layouts?.findIndex(
												(l) => l === photo
											) ?? -1;

										console.log(
											"layoutIndex, phone :>> ",
											layoutIndex,
											photo,
											selectedHeader?.layouts?.findIndex(
												(l) => {
													console.log(
														"l :>> ",
														l,
														photo
													);

													return l === photo;
												}
											)
										);
										return layoutIndex !== -1
											? layoutIndex + 1
											: index + 1;
									})
								);
							}}
							onRemove={(index) => console.log(index)}
						/>
					</div>
				</div>
			)}

			<div className="grid grid-cols-3 gap-4">
				<div className="mt-4 border border-gray-300 px-4 py-1">
					<p className="text-lg font-semibold">Header Settings :</p>
					<SettingFormItems
						settings={settings}
						settingItems={headerSettings}
						onChange={(key, value) => {
							setSettings((prev) => ({
								...prev,
								[key]: value,
							}));
						}}
					/>
				</div>

				<div className="col-span-2 mt-4 border border-gray-300 px-4 py-1">
					<p className="text-lg font-semibold">Header Menues :</p>

					<div className="flex gap-4 items-center">
						<p>Main Menu :</p>
						<div className="flex items-center gap-4">
							<div className="relative">
								<input
									type="text"
									placeholder="Search Menu..."
									value={menuText}
									onChange={handleMenuSearch("main")}
								/>
								<div className="bg-gray-100 drop-shadow-md shadow-inner rounded">
									{menues.length > 0 &&
										showMenu === "main" && (
											<div
												className="pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar"
												ref={menuSuggestion}
												// onScroll={handleMenuScroll}
											>
												{menues.map((menu, index) => {
													return (
														<div
															key={index}
															onClick={() => {
																handleMenuClick();
																setMainMenu(
																	menu
																);
															}}>
															<div className="flex items-center gap-4 py-2 cursor-pointer">
																<div className="text-sm font-light tracking-wide truncate">
																	{menu.name}
																</div>
															</div>
														</div>
													);
												})}
											</div>
										)}
								</div>
							</div>

							{mainMenu && (
								<div className="p-4 py-2 border border-gray-300 rounded-md shadow-sm">
									<p>{mainMenu.name}</p>
								</div>
							)}
						</div>
					</div>

					<div className="flex gap-4 items-center mt-4">
						<p>Navigation Menu :</p>
						<div className="flex items-center gap-4">
							<div className="relative">
								<input
									type="text"
									placeholder="Search Menu..."
									value={menuText}
									onChange={handleMenuSearch("nav")}
								/>
								<div className="bg-gray-100 drop-shadow-md shadow-inner rounded">
									{menues.length > 0 &&
										showMenu === "nav" && (
											<div
												className="pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar"
												ref={menuSuggestion}
												// onScroll={handleMenuScroll}
											>
												{menues.map((menu, index) => {
													return (
														<div
															key={index}
															onClick={() => {
																handleMenuClick();
																setNavMenu(
																	menu
																);
															}}>
															<div className="flex items-center gap-4 py-2 cursor-pointer">
																<div className="text-sm font-light tracking-wide truncate">
																	{menu.name}
																</div>
															</div>
														</div>
													);
												})}
											</div>
										)}
								</div>
							</div>

							{navMenu && (
								<div className="p-4 py-2 border border-gray-300 rounded-md shadow-sm">
									<p>{navMenu.name}</p>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="mt-4 border border-gray-300 px-4 py-1">
					<p className="text-lg font-semibold">
						Header Color Settings :
					</p>
					<SettingFormItems
						settings={settings}
						settingItems={colorSettings}
						onChange={(key, value) => {
							setSettings((prev) => ({
								...prev,
								[key]: value,
							}));
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default HeaderSettings;
