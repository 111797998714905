import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useEffect, useRef, useState } from "react";

import {
	AccessibilityHelp,
	AutoLink,
	Autosave,
	Bold,
	ClassicEditor,
	Essentials,
	FontBackgroundColor,
	FontColor,
	Italic,
	Link,
	Paragraph,
	SelectAll,
	SourceEditing,
	SpecialCharacters,
	Strikethrough,
	Subscript,
	Superscript,
	Underline,
	Undo,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";

import "./contenteditor.css";

export default function TextEditor({
	value,
	onEventChangeHandler,
}: {
	value?: string;
	onEventChangeHandler: (value: string, editor?: any) => void;
}) {
	const editorContainerRef = useRef(null);
	const editorRef = useRef(null);
	const [isLayoutReady, setIsLayoutReady] = useState(false);

	useEffect(() => {
		setIsLayoutReady(true);

		return () => setIsLayoutReady(false);
	}, []);

	const editorConfig = {
		toolbar: {
			items: [
				"sourceEditing",
				"|",
				"fontColor",
				"fontBackgroundColor",
				"underline",
				"bold",
				"italic",
				"|",
				"strikethrough",
				"subscript",
				"superscript",
				"|",
				"specialCharacters",
				"link",
				"selectAll",
				"|",
				"accessibilityHelp",
				"undo",
				"redo",
			],
			shouldNotGroupWhenFull: false,
		},
		plugins: [
			AccessibilityHelp,
			AutoLink,
			Autosave,
			Essentials,
			FontBackgroundColor,
			FontColor,
			Bold,
			Italic,
			Link,
			Paragraph,
			SelectAll,
			SourceEditing,
			SpecialCharacters,
			Strikethrough,
			Subscript,
			Superscript,
			Underline,
			Undo,
		],
		link: {
			addTargetToExternalLinks: true,
			defaultProtocol: "https://",
			decorators: {
				toggleDownloadable: {
					mode: "manual",
					label: "Downloadable",
					attributes: {
						download: "file",
					},
				},
			},
		},
		placeholder: "Type your text here!",
	};

	return (
		<div>
			<div
				ref={editorRef}
				className="w-[360px] [&_*.ck-content]:min-h-[100px]">
				{isLayoutReady && (
					<CKEditor
						editor={ClassicEditor as any}
						config={editorConfig as any}
						data={value}
						onChange={(event, editor) => {
							const data = editor?.getData();
							onEventChangeHandler(data, editor);
						}}
					/>
				)}
			</div>
		</div>
	);
}
